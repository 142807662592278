<template>
  <div class="my-16">
    <div class="container px-4 mx-auto">
      <div class="md:w-3/4 xl:w-2/4 mx-auto">
        <div class="text-center mb-4">
          <svg
            class="inline-block h-20 w-20 text-red"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
            />
            <line x1="12" y1="9" x2="12" y2="13" />
            <line x1="12" y1="17" x2="12.01" y2="17" />
          </svg>
        </div>
        <h2 class="text-3xl text-red text-center mb-4">Errore 404</h2>
        <h1 class="text-3xl text-red text-center mb-16">Pagina non trovata.</h1>
        <div class="text-center mb-16">
          <router-link
            to="/"
            class="
              bg-white
              text-md text-red
              uppercase
              border border-red
              px-4
              py-1
              hover:text-white hover:bg-red
              cursor-pointer
            "
            >Torna alla home</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "ThankYou",
  components: {},
};
</script>

<style scoped lang="scss">
</style>
